"use client";
import { Box } from "@mui/material";
import {
  StarBorderOutlined as StarIconOff,
  Star as StarIconOn
} from "@mui/icons-material";

import { observer, useStore } from "../../../service/mobx";
import Destination from "./Destination";

function MyStuff() {
  const { stars, user } = useStore();

  return user.loaded && stars.show && user.isAnonymous === false ? (
    <Destination
      text="My stuff"
      path="/search"
      match="stars"
      params="?stars=all"
      Off={StarIconOff}
      On={StarIconOn}
    />
  ) : (
    <Box width={56} height={56} />
  );
}

export default observer(MyStuff);
